import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';

type PaginationItemProps = {
  type: string;
  page: number | null;
  selected: boolean;
  path: string;
};

const PaginationItem = ({
  type,
  page,
  selected,
  path,
}: PaginationItemProps) => {
  const liClassNames = classNames(
    'flex',
    'mr-2',
    'rounded-sm',
    'text-sm',
    'min-w-[24px]',
    'h-[24px]'
  );

  let Icon;
  let text = '';

  switch (type) {
    case 'page':
      text = page ? page.toString() : '';
      break;
    case 'ellipsis':
      text = '...';
      break;
    case 'next':
      Icon = ChevronRight;
      break;
    case 'previous':
      Icon = ChevronLeft;
      break;
  }

  const linkTo = page ? `${path}?page=${page}` : '#';
  const disabled = !page;

  return (
    <li
      className={classNames(liClassNames, {
        'bg-blue-500': selected,
        'hover:bg-blue-500/[0.3]': !disabled && !selected,
      })}
    >
      <Link
        className={classNames(
          'flex',
          'justify-center',
          'items-center',
          'px-1',
          'text-center w-full h-full',
          {
            'pointer-events-none': disabled,
          }
        )}
        to={linkTo}
      >
        {text && text}
        {Icon && (
          <Icon
            className={classNames('h-5', 'w-5', {
              'opacity-40': disabled,
            })}
          />
        )}
      </Link>
    </li>
  );
};

export default PaginationItem;
