import React, { memo } from 'react';

type Props = {
  values: number[];
  percentageChange: number;
};

const Sparkline = memo(({ values, percentageChange }: Props) => {
  const sorted = new Float64Array(values).sort();
  const min = sorted[0];
  const max = sorted[sorted.length - 1];

  const lastItemIndex = values.length - 1;
  const offset = 100 / lastItemIndex;

  let points = '';
  for (let i = 0; i < values.length; i++) {
    const x = i * offset;
    const y = 100 - ((values[i] - min) / (max - min)) * 100;
    if (i != 0) points += ' ';
    points += `${x},${y}`;
  }

  return (
    <svg
      className="inline-block"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      width={135}
      height={50}
      preserveAspectRatio="none"
    >
      <polyline
        points={points}
        stroke={percentageChange > 0 ? 'green' : 'red'}
        strokeLinecap="round"
        strokeWidth={1.5}
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
});

Sparkline.displayName = 'Sparkline';

export default Sparkline;
