import React from 'react';
import useSWR from 'swr';
import { useSearchParams } from 'react-router-dom';
import CoinsTable from '../components/CoinsTable';

import type { Fetcher } from 'swr';
import type { CoinMarketData } from '../types/CoinMarketData';

type ListCoinsResponse = {
  data: CoinMarketData[];
  total: number;
};

const fetcher: Fetcher<ListCoinsResponse, string> = (url) =>
  fetch(url).then((res) => res.json());

const Home = () => {
  const [search, setSearch] = useSearchParams();
  const page = parseInt(search.get('page') ?? '1');

  const url = `${process.env.REACT_APP_API_URL}/coins?page=${page}`;
  const { data, error } = useSWR(url, fetcher);

  if (error) {
    return <div>Failed to load data</div>;
  }

  return (
    <CoinsTable
      coins={data?.data}
      total={data?.total}
      page={page}
      onPageChanged={(page: number) => setSearch({ page: page.toString() })}
    />
  );
};

export default Home;
