import React, { memo } from 'react';
import classNames from 'classnames';

type Props = {
  value: number;
};

const PercentageLabel = ({ value }: Props) => (
  <span
    className={classNames(
      'whitespace-nowrap',
      value > 0 ? 'text-green-500' : 'text-red-600'
    )}
  >
    <span
      className={classNames(
        'border-x-[5px] border-solid border-x-transparent border-current mt-[-2px] mr-[4px] inline-block align-middle',
        value > 0 ? 'border-b-[5px]' : 'border-t-[5px]'
      )}
    />
    {value > 0 ? value.toFixed(2) : (value * -1).toFixed(2)}%
  </span>
);

export default memo(PercentageLabel);
