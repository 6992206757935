import React, { memo } from 'react';
import PaginationItem from './PaginationItem';

type Props = {
  currentPage: number;
  pageSize: number;
  total?: number;
  path: string;
};

const Pagination = ({ currentPage, pageSize, total, path }: Props) => {
  if (!total) return <ul className="h-[25px]" />;

  const siblingCount = 1;
  const boundaryCount = 1;
  const totalPages = Math.ceil(total / pageSize);

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, totalPages));
  const endPages = range(
    Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
    totalPages
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1
  );

  const itemList = [
    ...['previous'],
    ...startPages,

    // Start ellipsis
    ...(siblingsStart > boundaryCount + 2
      ? ['ellipsis']
      : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < totalPages - boundaryCount - 1
      ? ['ellipsis']
      : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : []),

    ...endPages,
    ...['next'],
  ];

  const buttonPage = (type: string) => {
    switch (type) {
      case 'previous':
        return currentPage - 1;
      case 'next':
        return currentPage + 1;
      default:
        return null;
    }
  };

  return (
    <ul className="flex">
      {itemList.map((item: number | string, i) => {
        return typeof item === 'number' ? (
          <PaginationItem
            key={i}
            type="page"
            page={item}
            selected={item === currentPage}
            path={path}
          />
        ) : (
          <PaginationItem
            key={i}
            type={item}
            page={buttonPage(item)}
            selected={false}
            path={path}
          />
        );
      })}
    </ul>
  );
};

export default memo(Pagination);
