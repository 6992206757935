import React, { Suspense, lazy } from 'react';
import Layout from './components/Layout';
import { Route, Routes } from 'react-router-dom';
import Home from './pages';

const CoinPage = lazy(() => import('./pages/coins/[id]'));

function App() {
  return (
    <Layout>
      <Suspense>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/coins/:id" element={<CoinPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
