import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PercentageLabel from './PercentageLabel';
import CurrencyLabel from './CurrencyLabel';
import Sparkline from './Sparkline';
import Pagination from './Pagination';

import type { CoinMarketData } from '../types/CoinMarketData';

const CoinNameCell = ({ cell }: any) => {
  const coin: CoinMarketData = cell.row.original;
  return (
    <div className="flex items-center">
      <Link to={`/coins/${coin.id}`}>
        <div className="flex items-center">
          <img
            className="w-[24px] max-w-[24px] h-[24px] mr-2"
            src={coin.image}
            loading="lazy"
            alt={coin.symbol.toUpperCase()}
          />
          <div className="flex flex-col leading-4">
            <span>{coin.name}</span>
            <span className="text-white/[0.7] font-normal uppercase">
              {coin.symbol}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

const SparklineCell = ({ cell }: any) => {
  const coin: CoinMarketData = cell.row.original;
  return (
    <Link to={`/coins/${coin.id}`}>
      <Sparkline
        values={coin.prices}
        percentageChange={coin.price_change_percentage_7d}
      />
    </Link>
  );
};

type Props = {
  coins?: CoinMarketData[];
  total?: number;
  page: number;
  onPageChanged: any;
};

const CoinsTable = ({ coins, total, page }: Props) => {
  const isLoading = !coins;
  const pageSize = 50;

  const columns: any = useMemo(
    () => [
      {
        Header: '#',
        accessor: (coin: CoinMarketData) => coin.rank,
        Skeleton: () => <Skeleton width={16} />,
        classNames: 'text-left w-[50px]',
      },
      {
        Header: 'Coin',
        accessor: (coin: CoinMarketData) => coin.name,
        Cell: CoinNameCell,
        Skeleton: (cell: any) => {
          return (
            <div className="flex items-center">
              <Skeleton className="mr-2" width={24} height={24} circle />
              <div className="flex flex-col">
                <Skeleton width={cell.row.index === pageSize - 1 ? 140 : 80} />
                <Skeleton width={40} />
              </div>
            </div>
          );
        },
        classNames: 'text-left',
      },
      {
        Header: 'Price',
        accessor: (coin: CoinMarketData) => coin.price,
        Cell: (cell: any) => <CurrencyLabel value={cell.value} />,
        Skeleton: () => <Skeleton width={82} />,
      },
      {
        Header: '24h %',
        accessor: (coin: CoinMarketData) => coin.price_change_percentage_24h,
        Cell: (cell: any) => <PercentageLabel value={cell.value} />,
        Skeleton: () => <Skeleton width={60} />,
      },
      {
        Header: '7d %',
        accessor: (coin: CoinMarketData) => coin.price_change_percentage_7d,
        Cell: (cell: any) => <PercentageLabel value={cell.value} />,
        Skeleton: () => <Skeleton width={60} />,
      },
      {
        Header: 'Market Cap',
        accessor: (coin: CoinMarketData) => coin.market_cap,
        Cell: (cell: any) => (
          <CurrencyLabel
            value={cell.value}
            minPrecision={2}
            maxPrecision={2}
            compact={true}
          />
        ),
        Skeleton: () => <Skeleton width={60} />,
      },
      {
        Header: 'Volume (24h)',
        accessor: (coin: CoinMarketData) => coin.volume_24h,
        Cell: (cell: any) => (
          <CurrencyLabel
            value={cell.value}
            minPrecision={2}
            maxPrecision={2}
            compact={true}
          />
        ),
        Skeleton: () => <Skeleton width={63} />,
      },
      {
        Header: 'Last 7 Days',
        accessor: (coin: CoinMarketData) => coin.volume_24h,
        Cell: SparklineCell,
        Skeleton: () => <Skeleton width={135} />,
      },
    ],
    []
  );

  const tableData = useMemo(
    () => (isLoading ? Array(pageSize).fill({}) : coins),
    [isLoading, coins]
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column: any) => ({
            ...column,
            Cell: column.Skeleton || <Skeleton />,
          }))
        : columns,
    [isLoading, columns]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableColumns, data: tableData });

  return (
    <SkeletonTheme baseColor="rgba(255,255,255,0.1)" enableAnimation={false}>
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          className="table-auto w-full text-right text-sm font-semibold overflow-y-hidden"
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      className: classNames(
                        'bg-gray-800',
                        'p-2',
                        'border-b',
                        'border-b-white/[0.1]',
                        'whitespace-nowrap',
                        column.classNames
                      ),
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...row.getRowProps()}
                  className="h-[67px] hover:bg-white/[0.05]"
                >
                  {row.cells.map((cell: any) => (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      {...cell.getCellProps({
                        className: classNames(
                          'p-2',
                          'border-b',
                          'border-b-white/[0.1]',
                          cell.column.classNames
                        ),
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="py-6 flex justify-center md:justify-end">
        <Pagination
          currentPage={page}
          pageSize={pageSize}
          total={total}
          path="/"
        />
      </div>
    </SkeletonTheme>
  );
};

export default CoinsTable;
