import React, { memo } from 'react';
import { currency } from '../utils/formatters';

type Props = {
  value: number;
  minPrecision?: number;
  maxPrecision?: number;
  compact?: boolean;
};

const CurrencyLabel = ({
  value,
  maxPrecision = 8,
  minPrecision = 2,
  compact = false,
}: Props) => (
  <>
    {currency(
      value,
      minPrecision,
      maxPrecision,
      compact ? 'compact' : 'standard'
    )}
  </>
);

export default memo(CurrencyLabel);
