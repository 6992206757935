const currencyFormatters: { [key: string]: Intl.NumberFormat } = {};

function getFormatter(
  style: 'decimal' | 'currency' | 'percent' | 'unit',
  minPrecision: number,
  maxPrecision: number,
  notation: 'standard' | 'scientific' | 'engineering' | 'compact'
): Intl.NumberFormat {
  const key = `${style}_${minPrecision}_${maxPrecision}_${notation}`;
  if (currencyFormatters[key]) {
    return currencyFormatters[key];
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: style,
    currency: style === 'currency' ? 'USD' : undefined,
    notation: notation,
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });

  currencyFormatters[key] = formatter;
  return formatter;
}

export function currency(
  value: number,
  minPrecision = 2,
  maxPrecision = 8,
  notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard'
): string {
  return getFormatter('currency', minPrecision, maxPrecision, notation).format(
    value
  );
}

export function decimal(
  value: number,
  minPrecision = 2,
  maxPrecision = 8,
  notation: 'standard' | 'scientific' | 'engineering' | 'compact' = 'standard'
): string {
  return getFormatter('decimal', minPrecision, maxPrecision, notation).format(
    value
  );
}
