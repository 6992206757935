import React from 'react';
import { Link } from 'react-router-dom';
import GlobalDataHeader from './GlobalDataHeader';

type Props = {
  children: JSX.Element;
};

const Layout = ({ children }: Props) => {
  return (
    <div className="min-h-full">
      <nav className="bg-gray-700">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    alt="Crypto"
                  />
                </div>
                <div className="ml-4 flex items-baseline space-x-4">
                  <span className="text-base font-bold text-white ">
                    Cryptocurrency Prices
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </nav>
      <div className="max-w-7xl mx-auto px-4">
        <GlobalDataHeader />
      </div>
      <main className="max-w-7xl mx-auto py-4 px-4">{children}</main>
    </div>
  );
};

export default Layout;
