import React from 'react';
import useSWR from 'swr';
import { Fetcher } from 'swr';
import CurrencyLabel from './CurrencyLabel';
import PercentageLabel from './PercentageLabel';
import { decimal } from '../utils/formatters';

type GlobalData = {
  total_coins: number;
  total_markets: number;
  total_market_cap: { [key: string]: number };
  total_volume: { [key: string]: number };
  market_cap_percentage: { [key: string]: number };
  market_cap_change_percentage_24h: number;
};

type DataItem = {
  label: string;
  children: React.ReactNode;
};

const fetcher: Fetcher<GlobalData, string> = (url) =>
  fetch(url).then((res) => res.json());

const DataItem = ({ label, children }: DataItem) => {
  return (
    <>
      <span className="flex flex-col md:flex-row mr-4 whitespace-nowrap">
        <span className="text-white/[0.7]">{label}:&nbsp;</span>
        <span className="font-medium">{children}</span>
      </span>
    </>
  );
};

const GlobalDataHeader = () => {
  const url = `${process.env.REACT_APP_API_URL}/global`;
  const { data } = useSWR(url, fetcher);

  return (
    <div className="flex py-4 text-xs overflow-x-auto no-scrollbar min-h-[64px] md:min-h-[48px]">
      {data && (
        <>
          <DataItem label={'Coins'}>{decimal(data.total_coins, 0, 0)}</DataItem>
          <DataItem label={'Market Cap'}>
            <CurrencyLabel
              value={data.total_market_cap['usd']}
              minPrecision={0}
              maxPrecision={0}
            />
          </DataItem>
          <DataItem label={'24h Change'}>
            <PercentageLabel value={data.market_cap_change_percentage_24h} />
          </DataItem>
          <DataItem label={'24h Volume'}>
            <CurrencyLabel
              value={data.total_volume['usd']}
              minPrecision={0}
              maxPrecision={0}
            />
          </DataItem>
          <DataItem label={'Dominance'}>
            {Object.keys(data.market_cap_percentage)[0].toUpperCase()}:{' '}
            {Object.values(data.market_cap_percentage)[0].toFixed(1)}%,{' '}
            {Object.keys(data.market_cap_percentage)[1].toUpperCase()}{' '}
            {Object.values(data.market_cap_percentage)[1].toFixed(1)}%
          </DataItem>
        </>
      )}
    </div>
  );
};

export default GlobalDataHeader;
